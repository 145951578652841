import React from "react";
import "../Styles/App.css";
import Die from "./Die"
import Confetti from "react-confetti"

export default function App() {
    const [diceArray, setDiceArray] = React.useState(generateDiceArray());
    const [tenzies, setTenzies] = React.useState(false);
    const [rolls, setRolls] = React.useState(0);

    React.useEffect(() => {
        const firstValue = diceArray[0].value;
        const allSame = diceArray.every(die => die.value === firstValue);
        const allHeld = diceArray.every(die => die.isHeld);
        setTenzies(allHeld && allSame);
    }, [diceArray]);

    function toggleDie(id) {
        setDiceArray(prevDiceArray => (
            prevDiceArray.map(prevDie => {
                if (prevDie.id === id) {
                    return {
                        ...prevDie,
                        isHeld: !prevDie.isHeld
                    }
                } else {
                    return prevDie;
                }
            })
        ))
    }

    function getNewDice() {
        setDiceArray(prevDiceArray => {
            if (tenzies) {
                setRolls(0);
                return generateDiceArray();
            } else {
                setRolls(prevRolls => prevRolls + 1);
                return prevDiceArray.map(prevDie => {
                    if (!prevDie.isHeld) {
                        return {
                            ...prevDie,
                            value: Math.ceil(Math.random() * 6)
                        }
                    } else {
                        return prevDie;
                    }
                })
            }
        });
    }

    function generateDiceArray() {
        const diceArray = [];
        for (let i = 0; i < 10; i++) {
            const dieObj = {
                id: i,
                value: Math.ceil(Math.random() * 6),
                isHeld: false
            }
            diceArray.push(dieObj);
        }
        return diceArray;
    }

    const diceElements = diceArray.map(dieObj =>
        <Die
            key={dieObj.id}
            id={dieObj.id}
            value={dieObj.value}
            isHeld={dieObj.isHeld}
            toggleDie={toggleDie}
        />
    )

    return (
        <main className="App">
            <h1 className="App-title">Tenzies</h1>
            <p className="App-text">Roll until all dice are the same. Click each die to freeze it at its current value between rolls.</p>
            <h2 className="App-rollCount">Roll Count: {rolls}</h2>
            <div className="App-diceBoard">
                {diceElements}
            </div>
            <button className="App-button" onClick={getNewDice}>
                {tenzies ? "New Game" : "Roll"}
            </button>
            {tenzies && <Confetti />}
        </main>
    )
}