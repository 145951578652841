import React from "react";
import "../Styles/Die.css"

export default function Die(props) {
    const style = {
        backgroundColor: props.isHeld ? "#59E391" : "white"
    }

    return (
        <div className="Die" style={style} onClick={() => props.toggleDie(props.id)}>
            <p className="Die-value">{props.value}</p>
        </div>
    );
}